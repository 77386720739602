<template>
  <div>
    <div class="title">种类齐全</div>
    <div style="text-align: center; line-height: 20px; margin-top: 10px;">
      发布时间：2022-04-11 20:19:26
    </div>
    <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>已合作1000+品牌 100+平台渠道10万+全球优质产品，以丰富的全品类商品链接商家和用户，通过商城和供应链彼此之间的链接服务客户打造好用的私域商城平台，每一件商品都是精挑细选而来，只为能够以更优惠的价格，更优良的品质满足您的需求！</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fullRangeZj'
}
</script>

<style scoped>

</style>
