<template>
  <div>
    <div v-if="'hetun' === heTunInfo"><fullRangeZj></fullRangeZj></div>
    <div v-else><div class="title">种类齐全</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
      发布时间：2022-04-11 20:09:40
    </div><div style="font-size: 14px;"><p>&nbsp;</p>
      <p><span style="font-size: 16pt;"><strong>200+海外购物平台，8000+国际知名品牌，2000万+全球优质产品，专业采购团队实时跟踪市场上口碑、销量俱佳的进口商品，<br>每一件商品都是精挑细选而来，只为能够以更优惠的价格，更优良的品质满足您的需求。</strong></span></p></div></div>
  </div>
</template>

<script>
import fullRangeZj from '@/views/Help/components/fullRangeZj'

/**
 * @author  XuHongli
 * @date  2022/11/15 10:27
 * @version 1.0
 * @description
 */
export default {
  name: 'FullRange',
  components: { fullRangeZj },
  computed: {
    heTunInfo() {
      if (process.env.NODE_ENV === 'production') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'pre') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'hetun') {
        return process.env.NODE_ENV
      } else {
        return process.env.NODE_ENV
      }
    }
  }
}
</script>

<style scoped>

</style>
